import {graphql} from 'gatsby'
import React from 'react'

import BlockText from '../components/block-text'
import Layout from '../containers/layout'

import '../styles/layout.css'
import {paragraph} from '../components/typography.module.css'
import {cn} from '../lib/helpers'

export const query = graphql`
  query AboutPageQuery {
    aboutPage: allSanityAboutPage {
      edges {
        node {
          emailAddress
          _rawBio
        }
      }
    }
  }
`

const AboutPage = ({ data: { aboutPage } }) => {
  const { _rawBio, emailAddress } = aboutPage.edges[0].node
  return (
    <Layout>
      <div className={cn(paragraph, 'rich-text')}>
        { !!_rawBio && <BlockText blocks={_rawBio} /> }
      </div>
      <div className={paragraph} style={{ paddingTop: '1rem' }}>
        { `Contact: ` }
        <a href={`mailto:${emailAddress}`} target='_blank'>{emailAddress}</a>
      </div>
    </Layout>
  )
}

export default AboutPage
